import {
  LOADING_USER,
  USER_ACTIVATION_ERROR,
  USER_ERROR,
  CLEAR_USER_ERROR,
  USER_ACTIVATION_SUCCESS,
  RESEND_EMAIL_USER_ID,
  RESEND_EMAIL_SUCCESS,
  SET_USER_DATA,
  SET_UNAUTHENTICATED,
  APPLY_MODAL_OPEN,
  USER_PROFILE_EDIT_SUCCESS,
  GET_COUNTRY,
  GET_HUB_ADDRESS,
  GET_FRANCHISE_ADDRESS,
  GET_FRANCHISE_META,
  FORM_SUBMIT_SUCCESS,
  FETCH_HOW_THIS_WORK,
  FETCH_TERMS_AND_CONDITIONS,
  FETCH_PRIVACY_POLICY,
  FETCH_SITE_META,
  FETCH_MAP_DATA,
  FETCH_PROFILT_DATA,
  FETCH_TOTAL_PROFILT_DATA,
  FETCH_EHUB_DATA,
} from "../types";
import axios from "axios";
import { API_URL } from "../../settings/config";
import auth from "../../utils/auth";
import useAxios from "../../utils/useAxios";

export const applyModalToggle = (data) => (dispatch) => {
  dispatch({ type: APPLY_MODAL_OPEN, payload: data });
};

export const signupUser = (newUserData, history) => async (dispatch) => {
  console.log(newUserData);
  dispatch({ type: LOADING_USER });

  await axios
    .post(`${API_URL}/users/registration/`, newUserData)
    .then((res) => {
      console.log(res.data);
      dispatch({ type: RESEND_EMAIL_USER_ID, payload: res.data.user_id });
      if (res.data.user_id) {
        localStorage.setItem("activation_id", res.data.user_id);
      }
      //   dispatch({
      //     type: SIGNUP_SUCCESS,
      //   });
      console.log(history);
      dispatch(clearUserError());
      history("/account/activation");
    })
    .catch((err) => {
      console.log(err.response);
      const data = err.response?.data?.error;
      const commonErr = [];
      if (data) {
        for (const [i, j] of Object.entries(data)) {
          console.log(`${i}: ${j}`);
          commonErr.push(`${j}`);
        }
      }

      console.log(commonErr);
      if (data) {
        // dispatch(authError(commonErr));
        dispatch({
          type: USER_ERROR,
          payload: commonErr,
        });
      } else {
        dispatch({
          type: USER_ERROR,
          payload: err.response.data.message,
        });
      }
    });
};

export const signInUser = (newUserData, history) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  await axios
    .post(`${API_URL}/users/login/`, newUserData)
    .then((res) => {
      console.log(res);
      console.log(res?.response?.status);
      if (res?.response?.status === 400) {
        dispatch({
          type: USER_ERROR,
          payload: res.response.data.message,
        });
      } else {
        const jwt = `Bearer ${res.data.data.access}`;
        auth.setToken(jwt);
        dispatch(getUserData());
        dispatch(clearUserError());
        history("/my-panel");
      }
    })
    .catch((err) => {
      dispatch({
        type: USER_ERROR,
        payload: err.response.data.message,
      });
      
      if (err.response.data.user_id) {
        localStorage.setItem(
          "activation_id",
          err.response.data.user_id
        );
        dispatch({
          type: RESEND_EMAIL_USER_ID,
          payload: err.response.data.user_id,
        });
        history("/account/activation");
      }
    });
};

export const getUserData = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let api = useAxios();
  await api
    .get(`/users`)
    .then((res) => {
      auth.setUserInfo(res.data);
      dispatch({
        type: SET_USER_DATA,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const userAccountActivation = (code) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  await axios
    .post(`${API_URL}/users/account/confirm/`, { code })
    .then((res) => {
      console.log(res);
      dispatch({ type: USER_ACTIVATION_SUCCESS });
      // dispatch(clearUserError());
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: USER_ACTIVATION_ERROR,
        payload: err.response.data.message,
      });
    });
};

export const clearUserError = () => (dispatch) => {
  dispatch({ type: CLEAR_USER_ERROR });
};

export const userAccountCodeResend = (id) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  await axios
    .post(`${API_URL}/users/account/resend/code/`, { new_user_id: id })
    .then((res) => {
      console.log(res);
      dispatch({ type: RESEND_EMAIL_SUCCESS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ACTIVATION_ERROR, payload: err.response.message });
    });
};

export const userEditProfile = (data) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let api = useAxios();
  await api
    .patch(`/users/profile/edit/`, data)
    .then((res) => {
      console.log(res);
      dispatch({ type: USER_PROFILE_EDIT_SUCCESS });
      dispatch(getUserData());
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ACTIVATION_ERROR, payload: err.response.message });
    });
};

export const passwordResetReq = (data, history) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  let api = useAxios();
  await api
    .post(`/users/password/reset`, data)
    .then((res) => {
      console.log(res.data);
      history("/reset-password/");
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ACTIVATION_ERROR, payload: err.response.message });
    });
};

export const passwordResetConfirm = (data, history) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let api = useAxios();
  await api
    .post(`/users/password/reset/confirm`, data)
    .then((res) => {
      console.log(res.data);
      dispatch(logoutAction());
      history("/login");
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ACTIVATION_ERROR, payload: err.response.message });
    });
};

export const getCountry = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  await axios
    .get(`${API_URL}/meta/country/`)
    .then((res) => {
      // auth.setUserInfo(res.data);
      dispatch({
        type: GET_COUNTRY,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const getHubAddress = (countryId) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  await axios
    .get(`${API_URL}/meta/address/`, {
      params: {
        country: countryId,
      },
    })
    .then((res) => {
      // auth.setUserInfo(res.data);
      dispatch({
        type: GET_HUB_ADDRESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const getFranchiseAddress = (countryId) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  await axios
    .get(`${API_URL}/meta/franchise/address/`, {
      params: {
        country: countryId,
      },
    })
    .then((res) => {
      // auth.setUserInfo(res.data);
      dispatch({
        type: GET_FRANCHISE_ADDRESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const hubMetaAction = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  await axios
    .get(`${API_URL}/meta/franchise/meta/`)
    .then((res) => {
      // auth.setUserInfo(res.data);
      dispatch({
        type: GET_FRANCHISE_META,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const contactUsAction = (data) => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  await axios
    .post(`${API_URL}/meta/contact-us/`, data)
    .then((res) => {
      console.log(res.data);
      dispatch({ type: FORM_SUBMIT_SUCCESS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const howThisWorkAction = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  await axios
    .get(`${API_URL}/meta/how-this-work/`)
    .then((res) => {
      console.log(res.data);
      dispatch({ type: FETCH_HOW_THIS_WORK, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const termsOfServiceAction = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  await axios
    .get(`${API_URL}/meta/terms/`)
    .then((res) => {
      console.log(res.data);
      dispatch({ type: FETCH_TERMS_AND_CONDITIONS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const privacyPolicyAction = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  await axios
    .get(`${API_URL}/meta/privacy/`)
    .then((res) => {
      console.log(res.data);
      dispatch({ type: FETCH_PRIVACY_POLICY, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const siteMetaAction = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  await axios
    .get(`${API_URL}/meta/site/`)
    .then((res) => {
      console.log(res.data);
      dispatch({ type: FETCH_SITE_META, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const mapMetaAction = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });

  await axios
    .get(`${API_URL}/meta/map/`)
    .then((res) => {
      console.log(res.data);
      dispatch({ type: FETCH_MAP_DATA, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const ehubDataAction = () => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let api = useAxios();
  await api
    .get(`${API_URL}/meta/e-hub/own/`)
    .then((res) => {
      console.log(res.data);
      dispatch({ type: FETCH_EHUB_DATA, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: USER_ERROR, payload: err.response.message });
    });
};

export const getPurchaseData = (data) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let api = useAxios();
  await api
    .get(`/users/e-hub/person/list`, {
      params: {
        hub: data.id,
        filter_op: data.filter_op,
      },
    })
    .then((res) => {
      // auth.setUserInfo(res.data);
      dispatch({
        type: FETCH_PROFILT_DATA,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getTotalPurchaseData = (data) => async (dispatch) => {
  dispatch({ type: LOADING_USER });
  let api = useAxios();
  await api
    .get(`/users/e-hub/person/total/`, {
      params: {
        hub: data.id,
      },
    })
    .then((res) => {
      // auth.setUserInfo(res.data);
      dispatch({
        type: FETCH_TOTAL_PROFILT_DATA,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const logoutAction = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  dispatch({ type: SET_UNAUTHENTICATED });
};
